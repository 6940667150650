import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineMail } from "react-icons/md";
import { FullColumn } from "../../../components/utility/rowColumn";
import useAOS from "../../scrollAnimation";
import { logigloUrl } from "../../Config/index";
import { AiFillLinkedin } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa6";
import axios from "axios";
import { CPopover, CButton } from "@coreui/react";
import "./index.css";
const Navbar = React.lazy(() => import("../../Navbar"));
const Footer = React.lazy(() => import("../../LandingPageComponents/footer"));

const LandingpageContactUs = () => {
  const history = useHistory();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contactError, setContactError] = useState("");
  const [city, setCity] = useState("");
  const [message, setMessage] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [contactUsSectionData, setcontactUsSectionData] = useState([]);
  const [mailPopoverVisible, setMailPopoverVisible] = useState(false);
  const [linkedinPopoverVisible, setLinkedinPopoverVisible] = useState(false);
  const [facebookPopoverVisible, setFacebookPopoverVisible] = useState(false);
  const [playstorePopoverVisible, setPlaystorePopoverVisible] = useState(false);
  useAOS();

  useEffect(() => {
    // Fetch data from the backend when the component mounts
    axios
      .get(`${logigloUrl}/contactUsSection`)
      .then((response) => {
        setcontactUsSectionData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching carousel data:", error);
      });
  }, []);

  // Validation function for contact number
  const validateContact = () => {
    const contactPattern = /^\d{10}$/;

    if (!/^\d+$/.test(phoneNumber)) {
      setContactError("Contact number must be numbers only.");
      return false;
    } else if (phoneNumber.length !== 10) {
      setContactError("Contact number must be 10 digits.");
      return false;
    } else {
      setContactError("");
      return true;
    }
  };

  const handleMouseEnterMail = () => {
    setMailPopoverVisible(true);
  };

  const handleMouseLeaveMail = () => {
    setMailPopoverVisible(false);
  };

  const handleMouseEnterLinkedin = () => {
    setLinkedinPopoverVisible(true);
  };

  const handleMouseLeaveLinkedin = () => {
    setLinkedinPopoverVisible(false);
  };

  const handleMouseEnterFacebook = () => {
    setFacebookPopoverVisible(true);
  };

  const handleMouseLeaveFacebook = () => {
    setFacebookPopoverVisible(false);
  };

  const handleMouseEnterPlaystore = () => {
    setPlaystorePopoverVisible(true);
  };

  const handleMouseLeavePlaystore = () => {
    setPlaystorePopoverVisible(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate contact number
    const isValidContact = validateContact();

    if (!isValidContact) {
      return; // Exit the function if contact number is invalid
    }

    const formData = {
      name,
      email,
      phoneNumber,
      city,
      message,
    };

    try {
      const response = await axios.post(`${logigloUrl}/sendEmail`, formData);
      if (response.status === 200) {
        // Handle success (e.g., show a success message or redirect to a thank you page)
        // history.push("/thankyou");

        setName("");
        setEmail("");
        setPhoneNumber("");
        setCity("");
        setMessage("");

        // Set state to true to show a success message or perform any other action
        setIsSent(true);
        setTimeout(() => {
          setIsSent(false);
        }, 2000); // 2 seconds
      }
    } catch (error) {
      console.error("Error sending email:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  

  const navigateToLinkedIn = () => {
    window.location.href = "https://www.linkedin.com/company/logiglo/";
  };

  const navigateToFacebook = () => {
    window.location.href = "https://www.facebook.com/logiglocom";
  };

  const navigateToPlaystore = () => {
    window.location.href =
      "https://play.google.com/store/apps/datasafety?id=com.logiglo_mobile";
  };

  return (
    <FullColumn>
      <Navbar />
      <div className="contact-us-section-outer-con">
        {contactUsSectionData.map((item, index) => (
          <div
            className="contact-us-section-con"
            key={index}
            style={{ width: `${item.section_width}%` }}
            data-aos="fade-up"
          >
            <div className="contact-us-section-left-con">
              {/* <div className="carousel-head-con">
                <p className="carousel-header">Contact</p>
              </div> */}
              <div className="first-carousel-head-con">
                <div className="left-container">
                  <p className="what-wedo-header">{item.header}</p>
                </div>
                <div className="right-container">
                  {/* Any content you want for the right container */}
                </div>
              </div>
              <h3 className="contact-section-title">{item.title}</h3>
              <p className="contact-section-text">{item.description_text}</p>
           

              <div className="contact-icons-container">
                <div
                  className="each-icon-con"
                  onMouseEnter={handleMouseEnterMail}
                  onMouseLeave={handleMouseLeaveMail}
                >
                  <CPopover
                    content={item.email}
                    placement="bottom"
                    visible={mailPopoverVisible}
                  >
                    <div>
                      <MdOutlineMail className="icon-color" />
                    </div>
                  </CPopover>
                </div>

                <div
                  className="each-icon-con"
                  onClick={navigateToLinkedIn}
                  onMouseEnter={handleMouseEnterLinkedin}
                  onMouseLeave={handleMouseLeaveLinkedin}
                >
                  <CPopover
                    content="www.linkedin.com/company/logiglo"
                    placement="bottom"
                    visible={linkedinPopoverVisible}
                  >
                    <div>
                      <AiFillLinkedin className="icon-color" />
                    </div>
                  </CPopover>
                </div>

                <div
                  className="each-icon-con"
                  onClick={navigateToFacebook}
                  onMouseEnter={handleMouseEnterFacebook}
                  onMouseLeave={handleMouseLeaveFacebook}
                >
                  <CPopover
                    content="www.facebook.com/logiglocom"
                    placement="bottom"
                    visible={facebookPopoverVisible}
                  >
                    <div>
                      <FaFacebook className="icon-color" />
                    </div>
                  </CPopover>
                </div>
              </div>



            

                <div
                  // className="each-icon-con"
                  onClick={navigateToPlaystore}
                  onMouseEnter={handleMouseEnterPlaystore}
                  onMouseLeave={handleMouseLeavePlaystore}
                >
                <CPopover
                    content="Playstore"
                    placement="bottom"
                    visible={playstorePopoverVisible}
                  >
                    <div>
                    <img style={{width: '140px', height: '40px', marginTop: '10px', cursor: 'pointer'}} src="https://res.cloudinary.com/dwwzfhucu/image/upload/v1722342858/download_rwbgwo.png" 
                    alt="playstore-img"
                    />

                    </div>
                  </CPopover>
                  </div>


             
            </div>

            {/* onSubmit={sendEmail} */}
            <form
              className="contact-us-section-right-con"
              onSubmit={handleSubmit}
            >
              <div className="input-form-con">
                <input
                  className="contact-input-styles"
                  placeholder="Your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <input
                  className="contact-input-styles"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div className="input-form-con">
                <input
                  className="contact-input-styles"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                    setContactError("");
                  }}
                  required
                />

                <input
                  className="contact-input-styles"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              {contactError && <p style={{ color: "red" }}>{contactError}</p>}

              <div className="input-form-con">
                <textarea
                  placeholder="Your Message"
                  style={{
                    resize: "none",
                    paddingLeft: "10px",
                    borderRadius: "5px,",
                  }}
                  rows={10}
                  cols={100}
                  className="text-area-style"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </div>
              {isSent && (
                <p style={{ color: "green" }}>Email sent successfully!</p>
              )}
              <button className="contact-us-btn-styles" type="submit">
                Submit Message
              </button>
            </form>
          </div>
        ))}
      </div>
      {/* <Footer/> */}
    </FullColumn>
  );
};

export default LandingpageContactUs;
