import React, { Component, useEffect, useState, useRef } from "react";
import { useSelector, connect } from "react-redux";
import { Link } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
import Scrollbars from "../../components/utility/customScrollBar";
import IntlMessages from "../../components/utility/intlMessages";
import Advertisement from "../advertisement";
import appActions from "../../redux/app/actions";
import axios from "axios";
import { logigloUrl } from "../Config";
import CustomIcons from "../../assests/messages";
import logo from "../../assests/logo.png";
import LogoWhite from "../../assests/LogoWhite.png";
import {
  CBadge,
} from "@coreui/react";
import "./index.css";

import Drawer, {
  LogoWrapper,
  Lists,
  ListItem,
  ListItemIcon,
  ListItemText,
  ExpandLessIcon,
  ExpandMoreIcon,
} from "./style";

const { toggleCollapsed, changeOpenKeys, changeCurrent } = appActions;

let selectedTheme = {};

const ListLabel = ({ label }) => (
  <ListItemText
    style={{ color: selectedTheme.textColor }}
    inset
    primary={<IntlMessages id={label} />}
  />
);
const ListElement = ({
  leftIcon,
  label,
  children,
  optionCollapsed,
  isOpened,
  isNavTab,
}) => {
  return (
    <div className="ListItemWrapper">
      {leftIcon ? (
        <ListItemIcon style={{ color: selectedTheme.textColor }}>
          {leftIcon}
        </ListItemIcon>
      ) : null}
      <ListLabel label={label} />
      {children && !isNavTab ? (
        optionCollapsed ? (
          <ExpandLessIcon style={{ color: selectedTheme.textColor }}>
            expand_less
          </ExpandLessIcon>
        ) : (
          <ExpandMoreIcon style={{ color: selectedTheme.textColor }}>
            expand_more
          </ExpandMoreIcon>
        )
      ) : (
        ""
      )}
    </div>
  );
};

const LogoElem = ({ onLogo }) => {
  const accountId = useSelector((state) => state.Auth);

 
  return (
   
    <Link to={"/"} onClick={onLogo}>
      <img
        className="navbar-brand"
        style={{ width: "120px", height: "70px" }}
        src={LogoWhite}
        alt="Logiglo"
      />
    </Link>
  );
};

const stripTrailingSlash = (str) => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  state = {
    menuItems: [],
    dropdownMenu: "",
    pendingPostsCount: 0,
    pendingRepliesCount: 0,
    token: "",
    accountId: "",
    accountType: "",
  };

  componentDidMount() {

    axios
      .get(`${logigloUrl}/undermoderationPosts?page=${1}`)
      .then((res) => {
        this.setState({pendingPostsCount : res.data.totalCount})
      })
      .catch((error) => {
        console.log(error);
      });


      // axios
      // .get(`${logigloUrl}/pendingRepliesAndQuoteCounts`)
      // .then((res) => {
      //   this.setState({pendingRepliesCount : res.data.total_pending_count})
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
   
    axios
      .get(`${logigloUrl}/dropdownMenu`)
      .then((response) => {
        this.setState({ dropdownMenu: response.data });
        // console.log(response.data, "menu");
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${logigloUrl}/menuItems`)
      .then((response) => {
        this.setState({ menuItems: response.data });
       
      })
      .catch((error) => {
        console.log(error);
      });

    let accountType = null;
    let accountId = null;
    let token = null;

    // Check if token exists in localStorage
    const storedToken = localStorage.getItem("token");

    if (storedToken) {
      const parsedToken = JSON.parse(storedToken);
      // Check if accountType exists in parsedToken
      if (parsedToken && parsedToken.accountType) {
        this.setState({
          accountType: parsedToken.accountType,
          accountId: parsedToken.accountId,
          token: parsedToken.token,
        });
        
      }
    }
  }

  handleClick = () => {};
  onLogo = () => {
    const { changeOpenKeys, changeCurrent, toggleCollapsed } = this.props;
    changeOpenKeys({});
    changeCurrent({});
    toggleCollapsed();
  };
  render() {
    const { addsData, dropdownMenu, pendingPostsCount, token, accountId, accountType, menuItems } =
      this.state;

    

    const {
      changeOpenKeys,
      openKeys,
      collapsed,
      changeCurrent,
      current,
      anchor,
      height,
      customizedTheme,
      toggleCollapsed,
      fixedNavbar,
      view,
      pendingReplyCount
    } = this.props;
    selectedTheme = customizedTheme;
    const scrollheight = height;
    const url = stripTrailingSlash(this.props.url);
    const menuItem = (option) => {
      const { key, children, isNavTab } = option;
      const optionCollapsed = children && openKeys[key] === true;
      const isOpened = openKeys[key] === true;
      const linkTo = option.withoutDashboard ? `/${key}` : `${url}/${key}`;
      const collapsedClick = () => {
        if (children && !isNavTab) {
          changeOpenKeys({ [key]: !optionCollapsed });
        } else {
          changeCurrent({ [key]: !optionCollapsed });
        }
      };

      return (
        <div key={key}>
          {children && !isNavTab ? (
            <ListItem
              button
              onClick={collapsedClick}
              className={optionCollapsed ? "expands" : ""}
            >
              <ListElement
                {...option}
                isOpened={isOpened}
                optionCollapsed={optionCollapsed}
              />
            </ListItem>
          ) : (
            <ListItem
              className={current[key] ? "selected" : ""}
              onClick={collapsedClick}
            >
              <Link to={linkTo} onClick={toggleCollapsed}>
                <ListElement
                  {...option}
                  isOpened={isOpened}
                  optionCollapsed={optionCollapsed}
                />
              </Link>
            </ListItem>
          )}
          {optionCollapsed && !isNavTab ? (
            <Collapse in={true} timeout={200} unmountOnExit>
              {children.map(menuItem)}
            </Collapse>
          ) : (
            ""
          )}
        </div>
      );
    };

    const drawerWidth = window.innerWidth > 3000 ? "50%" : "100%";

    return (
      <Drawer
        // style={{ width: drawerWidth }}
        style={{backgroundColor: 'red', width: '0%'}}
        variant={
          view !== "TabLandView" && view !== "DesktopView"
            ? undefined
            : fixedNavbar
            ? "permanent"
            : undefined
        }
        open={!collapsed}
        onClose={toggleCollapsed}
        anchor={anchor}
        transitionDuration={100}
        className={`${collapsed && "collapsed"} ${fixedNavbar && "f1x3dnAV"}`}
      >
        <div
          className="drawerInner"
          // style={{width: '90%', background: customizedTheme.backgroundColor }}
          style={{background: customizedTheme.backgroundColor, width: '100%',}}
          //  style={{ background: "red", border : '1px solid black' }}
        >
          <LogoWrapper
            style={{
              backgroundColor: "#181c2e",
              display: "flex",
              justifyContent: "flex-start",
              paddingLeft: "10px",
              width: '100%'
            }}
          >
            <LogoElem onLogo={this.onLogo} />
          </LogoWrapper>
          <Scrollbars style={{ height: scrollheight - 64,width: '100%' }}>
            {/* <Lists>{options.map(menuItem)}</Lists> */}
            <div className="forum-left-con">
              <div
                className="nav-item dropdown forum-menu-items-in-leftside-nav"
                style={{ fontSize: "14px" }}
              >
                <ul
                  className="navbar-nav ml-auto sidebar-styles"
                  style={{ fontSize: "14px" }}
                >
                  {Array.isArray(menuItems) &&
                    menuItems.map(
                      (item) =>
                        item.display === 1 && (
                          <li className="nav-itemm" key={item.id}>
                            <div className="test-menu">
                              <CustomIcons
                                base64={item.icon_path}
                                style={{ backgroundColor: "white" }}
                              />

                              {/* <Link
                                className="nav-link"
                                to={
                                  accountType === "admin"
                                    ? item.menu_items === "Forum"
                                      ? token
                                        ? `/forumDashboard/admin`
                                        : "/signin?from=forum"
                                      : item.menu_items === "Careers"
                                      ? "/Careers/admin"
                                      : item.navigation_link
                                    : item.menu_items === "Forum"
                                    ? token
                                      ? `/forumDashboard`
                                      : `/forum`
                                    : item.navigation_link
                                }
                                style={{ marginLeft: "4px", width: "100%" }}
                              >
                                {item.menu_items}
                              </Link> */}

                              <Link
                                className="nav-link"
                                to={
                                  accountType === "admin"
                                    ? item.menu_items === "Forum"
                                      ? token
                                        ? `/forumDashboard`
                                        : `/forum`
                                      : item.menu_items === "Careers"
                                      ? "/Careers/admin"
                                      : item.navigation_link
                                    : item.menu_items === "Forum"
                                    ? token
                                      ? `/forumDashboard`
                                      : `/forum`
                                    : item.navigation_link
                                }
                                style={{ marginLeft: "4px", width: "100%" }}
                              >
                                {item.menu_items}
                              </Link>
                            </div>
                          </li>
                        )
                    )}

                  {accountType === "admin" && (
                    <li className="nav-itemm">
                      <Link
                        to="/forumDashboard/admin/PostModeration"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="test-menu">
                          <CustomIcons
                            base64={
                              "PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNDMzNTkgNS41Mzk5MUM2LjY0MDI2IDMuMTM5OTEgNy44NzM1OSAyLjE1OTkxIDEwLjU3MzYgMi4xNTk5MUgxMC42NjAzQzEzLjY0MDMgMi4xNTk5MSAxNC44MzM2IDMuMzUzMjUgMTQuODMzNiA2LjMzMzI1VjEwLjY3OTlDMTQuODMzNiAxMy42NTk5IDEzLjY0MDMgMTQuODUzMiAxMC42NjAzIDE0Ljg1MzJIMTAuNTczNkM3Ljg5MzU5IDE0Ljg1MzIgNi42NjAyNiAxMy44ODY2IDYuNDQwMjYgMTEuNTI2NiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTAuNDk5OCA4LjVIMi45MTMwOSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNC40MDAzMyA2LjI2NjZMMi4xNjY5OSA4LjQ5OTk0TDQuNDAwMzMgMTAuNzMzMyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K"
                            }
                          />

                          <Link
                            to="/forumDashboard/admin/"
                            className="nav-link"
                            style={{ marginLeft: "4px", width: "100%" }}
                          >
                            Pending Posts
                          </Link>

                          <CBadge color="warning" className="ms-2, me-2 text-dark">
                                        {pendingPostsCount}
                                    </CBadge>
                        </div>
                      </Link>
                    </li>
                  )}

                  {accountType === "admin" && (
                    <li className="nav-itemm">
                      <Link
                        to="/forumDashboard/admin/ReplyModeration"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="test-menu">
                          <CustomIcons
                            base64={
                              "PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNDMzNTkgNS41Mzk5MUM2LjY0MDI2IDMuMTM5OTEgNy44NzM1OSAyLjE1OTkxIDEwLjU3MzYgMi4xNTk5MUgxMC42NjAzQzEzLjY0MDMgMi4xNTk5MSAxNC44MzM2IDMuMzUzMjUgMTQuODMzNiA2LjMzMzI1VjEwLjY3OTlDMTQuODMzNiAxMy42NTk5IDEzLjY0MDMgMTQuODUzMiAxMC42NjAzIDE0Ljg1MzJIMTAuNTczNkM3Ljg5MzU5IDE0Ljg1MzIgNi42NjAyNiAxMy44ODY2IDYuNDQwMjYgMTEuNTI2NiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTAuNDk5OCA4LjVIMi45MTMwOSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNC40MDAzMyA2LjI2NjZMMi4xNjY5OSA4LjQ5OTk0TDQuNDAwMzMgMTAuNzMzMyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K"
                            }
                          />

                          <Link
                            to="/forumDashboard/admin/ReplyModeration"
                            className="nav-link"
                            style={{ marginLeft: "4px", width: "100%" }}
                          >
                            Pending Replies
                          </Link>

                           <CBadge color="warning" className="ms-2, me-2 text-dark">
                           {pendingReplyCount}
                                    </CBadge>
                        </div>
                      </Link>
                    </li>
                  )}

                 
                </ul>

                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                  style={{ fontSize: "14px" }}
                >
                  {Array.isArray(dropdownMenu) &&
                    dropdownMenu.map(
                      (item) =>
                        item.display === 1 && (
                          <Link
                            className="dropdown-item"
                            to={item.option_navigation_link}
                            key={item.id} // Assuming each item has a unique id
                          >
                            {item.company_dropdown}
                          </Link>
                        )
                    )}
                </div>
              </div>

                    <div className="sidebar-ads-con">
                      <Advertisement module="Forum" type="left"  />
                    </div>

             
            </div>
          </Scrollbars>
        </div>
      </Drawer>
    );
  }
}

export default connect(
  (state) => ({
    ...state.App,
    customizedTheme: state.ThemeSwitcher.sidebarTheme,
    pendingReplyCount: state.reply.pendingReplyCount
  }),
  { toggleCollapsed, changeOpenKeys, changeCurrent }
)(Sidebar);
