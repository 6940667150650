import React, { useEffect, useState, useRef } from "react";
import useAOS from "../../scrollAnimation";
import Aos from "aos";
import "aos/dist/aos.css";
import "./index.css";

const LandingPageVideo = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  return (
    <div style={{ width: "100vw", height: "100vh", overflow: "hidden", marginBottom: '100px' }}>
      <video
        src="https://res.cloudinary.com/dwwzfhucu/video/upload/v1734608276/WhatsApp_Video_2024-12-19_at_15.57.38_evfeu0.mp4"
        autoPlay
        loop
        muted
        // controls
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        //   marginTop: '100px'
        }}
      ></video>
    </div>
  );
};

export default LandingPageVideo;
