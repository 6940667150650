import React, { lazy, useEffect, useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import CorporateAccount from "./containers/Page/CorporateAccount";
import App from "./containers/App";
import Auth0 from "./helpers/auth0";
import actions from "./redux/auth/actions";
import Careers from "./containers/LandingPageComponents/AdminCareers";
import ForumDashboard from "./containers/forumHomePage";
import UserDashboard from "./containers/userDashboard"
import 'bootstrap/dist/css/bootstrap.min.css';
import useAOS from "./containers/scrollAnimation";
import ReactGA from 'react-ga';
import CanonicalLink from "./CanonicalLink"; // Import your CanonicalLink component
import CourseDetails from "./containers/LandingPageComponents/CourseDetails";
import UserProfile from "./containers/UserProfileDashboard/index.js";
import EducationCourseDetailedPage from "./containers/LandingPageComponents/Education/CourseDetailedPage/EducationCourseDetailedPage.js";
import { HelmetProvider } from 'react-helmet-async';
import BrowcherComponent from "./containers/LandingPageComponents/Brochures/temp.js";

import { Helmet } from 'react-helmet';
import Categories from "./containers/Categorizer";


const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        // <Redirect
        //   to={{
        //     pathname: '/signin',
        //     state: { from: props.location },
        //   }}
        // />
        <Component {...props} />
      )
    }
  />
);

const PublicRoutes = ({ history, isLoggedIn }) => {
  const dispatch = useDispatch();
  const [initialLoad, setInitialLoad] = useState(false);
  // const [samp, setSamp] = useState()

  useEffect(() => {
    const data = localStorage.getItem("token");
    if (data && Object.keys(data || {}).length) {
      const { token, accountId } = JSON.parse(data);
      // setSamp(JSON.parse(data))
      dispatch({
        type: actions.LOGIN_SUCCESS,
        token: token,
        accountId: accountId,
      });
    }
  }, []);

  


  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-PTE475PH2N');

    // Track initial page view
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);



  // useEffect(() => {
  //   // Initialize Google Analytics
  //   ReactGA.initialize("G-PTE475PH2N");

  //   // Track initial page view
  //   ReactGA.pageview(window.location.pathname + window.location.search);

  //   // Set canonical URL dynamically
  //   const canonicalUrl = window.location.href;
  //   const existingCanonical = document.querySelector('link[rel="canonical"]');

  //   if (existingCanonical) {
  //     existingCanonical.setAttribute("href", canonicalUrl); // Update existing canonical URL
  //   } else {
  //     const canonicalLink = document.createElement("link");
  //     canonicalLink.setAttribute("rel", "canonical");
  //     canonicalLink.setAttribute("href", canonicalUrl);
  //     document.head.appendChild(canonicalLink); // Add new canonical URL
  //   }
  // }, []);



  return (
    <HelmetProvider>
    <BrowserRouter>

    {/* <Helmet>
          <meta property="og:url" content="https://logiglo.com" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Logiglo Forum - Find Logistic Posts"
          />
          <meta
            property="og:description"
            content="Explore exciting career also."
          />
          <meta
             property="og:image"
            content="https://opengraph.b-cdn.net/production/images/f8ba140a-981c-4421-a09e-ebae95b0534f.png?token=FXByuwPXqDXbCAr2fdnElBC4FiDHbW6vOC8r-8b_Kg0&height=675&width=1200&expires=33259546757"
          />
        </Helmet> */}
      <>
        {/* <Route
          exact
          path="/"
          component={lazy(() => import('./containers/Page/signin'))}
        /> */}
        <CanonicalLink />
        
        <Route
          exact
          path="/"
          component={lazy(() => import("./containers/Page/HomePage"))}
        />
        <Route
          exact
          path="/signin"
          component={lazy(() => import("./containers/Page/signin"))}
        /><Route
        exact
        path="/forgotPassword"
        component={lazy(() => import("./containers/Page/forgetpassword/index"))}
      />
      <Route
        exact
        path="/resetPassword"
        component={lazy(() => import("./containers/Page/resetpassword/index"))}
      />
       <Route
        exact
        path="/themeSwitch"
        component={lazy(() => import("./containers/ThemeSwitcher/index"))}
       /> 
    <Route
      exact
      path="/animation"
      component={lazy(() => import("./containers/Page/animationTest/index"))}
    />
        <Route
          path="/auth0loginCallback"
          render={(props) => {
            Auth0.handleAuthentication(props);
          }}
        />
        <RestrictedRoute
          path="/forumDashboard"
          component={App}
          isLoggedIn={isLoggedIn}
        />
        <Route
          exact
          path="/404"
          component={lazy(() => import("./containers/Page/404"))}
        />
        <Route
          exact
          path="/505"
          component={lazy(() => import("./containers/Page/505"))}
        />
        <Route
          exact
          path="/signup"
          component={lazy(() => import("./containers/Page/signup"))}
        />
         
         <Route
          exact
          path="/linkedInSignup"
          component={lazy(() => import("./containers/Page/linkedInSignup/index"))}
        />
        <Route
          exact
          path="/corporateAccount"
          component={lazy(() => import("./containers/Page/CorporateAccount"))}
        />
        <Route
          exact
          path="/forgot-password"
          component={lazy(() => import("./containers/Page/forgetpassword"))}
        />
        <Route
          exact
          path="/reset-password"
          component={lazy(() => import("./containers/Page/resetpassword"))}
        />
        <Route
          exact
          path="/Careers/admin"
          component={lazy(() =>
            import("./containers/LandingPageComponents/AdminCareers")
          )}
        />
        <Route
          exact
          path="/Careers/admin/modify"
          component={lazy(() =>
            import("./containers/LandingPageComponents/AdminCareersControl")
          )}
        />
          <Route
          exact
          path="/Careers"
          component={lazy(() =>
            import("./containers/LandingPageComponents/UserCareers")
          )}
        />
        <Route
              exact
              path="/Careers/:id"
              component={lazy(() =>
                import("./containers/LandingPageComponents/UserCareersDetailsPage/index.js")
              )}
            />

        <Route
          exact
          path="/Education"
          component={lazy(() =>
            import("./containers/LandingPageComponents/Education")
          )}
        />
         <Route
          exact
          path="/course/:id"
          component={lazy(() =>
            import("./containers/LandingPageComponents/CourseDetails")
          )}
        />


        <Route
          exact
          path="/Magazines"
          component={lazy(() =>
            import("./containers/LandingPageComponents/Magazines")
          )}
        />

        <Route
          exact
          path="/AboutUs"
          component={lazy(() =>
            import("./containers/LandingPageComponents/AboutUs")
          )}
        />
        <Route
          exact
          path="/Events"
          component={lazy(() =>
            import("./containers/LandingPageComponents/Events/Card.js")
          )}
        />
        <Route

        
          exact
          path="/ContactUs"
          component={lazy(() =>
            import("./containers/LandingPageComponents/ContactUs")
          )}
        />

         <Route
          exact
          path="/Shop"
          component={lazy(() =>
            import("./containers/LandingPageComponents/Shop")
          )}
        />

<Route
          exact
          path="/Franchise"
          component={lazy(() =>
            import("./containers/LandingPageComponents/Franchise")
          )}
        />

<Route
          exact
          path="/Solutions"
          component={lazy(() =>
            import("./containers/LandingPageComponents/Solutions")
          )}
        />

        <Route
          exact
          path="/privacy"
          component={lazy(() =>
            import("./containers/LandingPageComponents/privacyPage")
          )}
        />

        <Route
          exact
          path="/terms"
          component={lazy(() =>
            import("./containers/LandingPageComponents/termsPage")
          )}
        />

        <Route
          exact
          path="/forum"
          component={lazy(() => import("./containers/forumHomePage"))}
        />
         <Route
          exact
          path="/theme"
          component={lazy(() => import("./containers/ThemeSwitcher/index"))}
        />


<Route
          exact
          path="/Blog"
          component={lazy(() =>
            import("./containers/LandingPageComponents/Blogs/Blog")
          )}
        />
        <Route
        exact
        path="/Blog/:id"
        component={lazy(() =>
          import("./containers/LandingPageComponents/Blogs/BlogDetails")
        )}
        />

        {/* <Route path="/eventcard" exact
        component={lazy(()=>import("./containers/LandingPageComponents/Events/Card"))}
         /> */}


          <Route
          exact
          path="/Events/Calendar"
          component={lazy(() => import("./containers/LandingPageComponents/Events/index"))}
        />
        <Route
          exact
          path="/Dashboard"
          component={lazy(() => import("./containers/LandingPageComponents/Events/Dashboard"))}
        />
          <Route
          exact
          path="/detailecard/:id"
          component={lazy(() => import("./containers/LandingPageComponents/Events/Detailcard"))}
        />
        <Route
          exact
          path="/Brochures"
          component={lazy(() =>
            import("./containers/LandingPageComponents/Brochures/temp.js")
          )}
        />
        <Route exact path="/CourseList"
          component={lazy(() => import("./containers/LandingPageComponents/Education/EducationCourses/CourseMainPage.js"))}
        />
        <Route
          exact
          path="/CourseDetailedInfo/:id"
          component={lazy(() => import("./containers/LandingPageComponents/Education/CourseDetailedPage/EducationCourseDetailedPage.js"))}
        />
        
      </>
    </BrowserRouter>
    </HelmetProvider>

  );
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.Auth.idToken !== null,
  };
}
export default connect(mapStateToProps)(PublicRoutes);
