import React, { useEffect, useState, useRef } from "react";
import useAOS from "../../scrollAnimation";
import Aos from "aos";
import "aos/dist/aos.css";

import "./index.css";
import { duration } from "moment";

const LandingPageAnimation = () => {


  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  


  const images = [
    'https://res.cloudinary.com/dwwzfhucu/image/upload/v1732255300/800kb_bkndyl.webp',
    'https://res.cloudinary.com/dwwzfhucu/image/upload/v1732255458/truck_3_bon2dk.webp',
    'https://res.cloudinary.com/dwwzfhucu/image/upload/v1732273911/student_2_o05dtr.webp',
    'https://res.cloudinary.com/dwwzfhucu/image/upload/v1733478471/e_comm__pvt71u.webp',
    'https://res.cloudinary.com/dwwzfhucu/image/upload/v1733478606/cart_img_wpv9jq.webp',
    'https://res.cloudinary.com/dwwzfhucu/image/upload/v1733478677/GD__beg5l2.webp',
    'https://res.cloudinary.com/dwwzfhucu/image/upload/v1732274192/event_comp_tnpagy.webp'
  ];

  


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);
  






  return (
    <div className="animated-images-con" style={{ backgroundImage: `url(${images[currentImageIndex]})` }}>
    <h1 className="text">Gateway of Logistics and Supply Chain World</h1>
    <p>Explore. Educate. Shop. Earn</p>
  </div>
  );
};

export default LandingPageAnimation;
